body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #777777;
  /* text-align: left; */
  background-color: #fff;
}

h1,
h2, 
h3, 
h4,
h5, 
h6 {
  color: #202C45;
}

a {
  color: #202C45;
  transition: .3s;
}

a:hover,
a:active,
a:focus {
  color: #E81C2E;
  outline: none;
  text-decoration: none;
}

.see-packegs-btn{
  padding: 10px 30px !important;
}

.btn.btn-custom {
  padding: 15px 50px;
  text-align: center; 
  font-size: 14px;
  letter-spacing: 0.3px;
  font-weight: 500;
  background: #e81c2e;
  color: #ffffff;
  text-transform: uppercase;
  border: none;
  border-radius: 60px;
  box-shadow: inset 0 0 0 0 #202C45;
  transition: ease-out 0.5s;
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  position: relative;
  z-index: 1;
}
.btn.btn-custom:hover{
  color: #fff;
  transition: 0.5s ease-in-out;
}

 .btn.btn-custom:before {
  position: absolute;
  content: '';
  width: 0%;
  height: 100%;
  background: #202C45;
  top: 0;
  left: 0;
  transition: 0.5s ease-in-out;
  border-radius: 30px;
  z-index: -1;
}
.btn.btn-custom:hover::before {
  width: 100%;
  transition: 0.5s ease-in-out;
}


/* .btn.btn-custom:hover {
  color: #E81C2E;
  background: #202C45;
  box-shadow: inset 200px 0 0 0 #202C45;
} */

.btn:focus,
.form-control:focus {
  box-shadow: none;
}

.container-fluid {
  max-width: 1366px;
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-size: inherit;
  margin-left: 0;
}


/**********************************/
/****** Loader & Back to Top ******/
/**********************************/
#loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .3s ease-out, visibility 0s linear .3s;
  -o-transition: opacity .3s ease-out, visibility 0s linear .3s;
  transition: opacity .3s ease-out, visibility 0s linear .3s;
  z-index: 999;
}

#loader.show {
  -webkit-transition: opacity .6s ease-out, visibility 0s linear 0s;
  -o-transition: opacity .6s ease-out, visibility 0s linear 0s;
  transition: opacity .6s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

#loader .loader {
  position: relative;
  width: 45px;
  height: 45px;
  border: 5px solid #dddddd;
  border-top: 5px solid #E81C2E;
  border-radius: 50%;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.back-to-top {
  position: fixed;
  display: none;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 1;
  font-size: 22px;
  right: 15px;
  bottom: 15px;
  transition: .5s;
  background: #E81C2E;
  border-radius: 44px;
  z-index: 9;
}

.back-to-top i {
  color: #ffffff;
  padding-top: 10px;
}

.back-to-top:hover {
  background: #202C45;
}

*, *::before, *::after {
  box-sizing: border-box;
}